// Generated by Framer (c60b0a0)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as localizedValues from "./eROapFcFb-0.js";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const cycleOrder = ["ObSIX9Vlk", "ZOYUBvVlT"];

const serializationHash = "framer-cdvYm"

const variantClassNames = {ObSIX9Vlk: "framer-v-b85j18", ZOYUBvVlT: "framer-v-vvi9qa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "ObSIX9Vlk", Select: "ZOYUBvVlT"}

const getProps = ({height, icon, iconColor, id, width, ...props}) => { return {...props, fHwAFte2V: iconColor ?? props.fHwAFte2V ?? "rgb(102, 102, 255)", gJgPl2K8M: icon ?? props.gJgPl2K8M ?? "Home", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ObSIX9Vlk"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string;iconColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gJgPl2K8M, fHwAFte2V, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ObSIX9Vlk", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-b85j18", className, classNames)} data-border data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"ObSIX9Vlk"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0.16)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-cb8c315f-aa79-46d0-9894-796d4f3c486e, rgb(248, 248, 248))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, boxShadow: "inset 0px 3px 4px 0px rgba(255, 255, 255, 0.1)", ...style}} variants={{ZOYUBvVlT: {"--border-bottom-width": "0.5px", "--border-color": "var(--token-e786d274-1fd4-44b2-8bb2-53d1a18b7911, rgb(82, 116, 255))", "--border-left-width": "0.5px", "--border-right-width": "0.5px", "--border-top-width": "0.5px"}}} {...addPropertyOverrides({ZOYUBvVlT: {"data-framer-name": "Select"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1pc5yod-container"} layoutDependency={layoutDependency} layoutId={"zjlepRe96-container"}><Material color={fHwAFte2V} height={"100%"} iconSearch={"Home"} iconSelection={gJgPl2K8M} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"zjlepRe96"} layoutId={"zjlepRe96"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cdvYm.framer-ijbc6c, .framer-cdvYm .framer-ijbc6c { display: block; }", ".framer-cdvYm.framer-b85j18 { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-cdvYm .framer-1pc5yod-container { flex: none; height: 18px; left: 3px; position: absolute; top: 3px; width: 18px; }", ".framer-cdvYm[data-border=\"true\"]::after, .framer-cdvYm [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ZOYUBvVlT":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gJgPl2K8M":"icon","fHwAFte2V":"iconColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramereROapFcFb: React.ComponentType<Props> = withCSS(Component, css, "framer-cdvYm") as typeof Component;
export default FramereROapFcFb;

FramereROapFcFb.displayName = "Category Elements/Category Icon Copy";

FramereROapFcFb.defaultProps = {height: 24, width: 24};

addPropertyControls(FramereROapFcFb, {variant: {options: ["ObSIX9Vlk", "ZOYUBvVlT"], optionTitles: ["Default", "Select"], title: "Variant", type: ControlType.Enum}, gJgPl2K8M: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "Home", description: undefined, hidden: undefined, title: "Icon"}, fHwAFte2V: {defaultValue: "rgb(102, 102, 255)", title: "IconColor", type: ControlType.Color}} as any)

addFonts(FramereROapFcFb, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})